import './App.css';

function App() {
  return (
    <div className="App">
      <img src={"/wtlb-logo.png"} className="App-logo" alt="logo" />
      <p style={{marginBottom: 0}}>
        WELCOME TO LUNCH BREAK
      </p>
      <p style={{margin: 0}}>
        Coming soon...
      </p>
    </div>
  );
}

export default App;
